<template>
  <div>
    <cbs-objects-tbl meta-filter="table" title="DATA TABLES" />
  </div>
</template>

<script>
import CbsObjectsTbl from '@/cubus/components/objects/CbsObjectsTbl.vue'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsObjectsTable',
  components: {
    CbsObjectsTbl,
  },
  created() {
    useCubus.setPageTitle('Tables')
  },
}
</script>

<style scoped>

</style>
